<template>
  <div>
    <div class="custom-grid-xl-container">
      <b-form-group
        id="input-group-1"
        label="Mã thủ tục hành chính"
        label-for="input-1"
        class="required grap-3"
      >
        <validation-provider
          v-slot="{ errors }"
          key="maThuTucHanhChinh"
          :rules="{ required: true }"
          name="maThuTucHanhChinh"
        >
          <b-form-input
            id="input-1"
            ref="maThuTucHanhChinh"
            v-model="dataForm.maTTHC"
            type="text"
            placeholder="Nhập mã thủ tục hành chính"
            required
            :autofocus="dataForm.id ? false : true"
          />
          <div class="label-noti-validate">
            {{ errors[0] }}
          </div>
          <div class="label-noti-validate">
            {{ errorTrungMa }}
          </div>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Số quyết định"
        label-for="input-1"
        class="grap-3"
      >
        <b-form-input
          id="input-1"
          ref="soQuyetDinh"
          v-model="dataForm.soQuyetDinh"
          type="text"
          placeholder="Nhập số quyết định"
          required
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Tên thủ tục hành chính"
        label-for="input-1"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenThuTucHanhChinh"
          :rules="{ required: true }"
          name="tenThuTucHanhChinh"
        >
          <b-form-input
            id="input-1"
            ref="tenThuTucHanhChinh"
            v-model="dataForm.tenTTHC"
            type="text"
            placeholder="Nhập tên thủ tục hành chính"
            required
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Lĩnh vực"
        label-for="input-1"
        placeholder="Chọn lĩnh vực"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="linhVuc"
          :rules="{ required: true }"
          name="linhVuc"
        >
          <v-select
            ref="linhVuc"
            v-model="data.linhVucId"
            class="style-chooser"
            placeholder="Nhập lĩnh vực"
            :options="lv"
            label="name"
            :reduce="se => se.id"
            :filter="onFilterSelect"
            @input="dataForm.linhVucId = data.linhVucId"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Cơ quan thực hiện"
        label-for="input-1"
        placeholder="Chọn cơ quan thực hiện"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="coQuanBanHanh"
          :rules="{ required: true }"
          name="coQuanBanHanh"
        >
          <treeselect
            id="coQuanBanHanh"
            ref="coQuanBanHanh"
            v-model="data.coQuanThucHien"
            v-format-v-select
            :options="dvCbx"
            placeholder="Chọn cơ quan thực hiện"
            :normalizer="normalizer"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            @input="dataForm.coQuanThucHien = data.coQuanThucHien"
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Phí"
        label-for="input-1"
        class="grap-4"
      >
        <b-form-input
          id="phiInput"
          type="text"
          placeholder="Nhập phí"
          required
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Lệ phí"
        label-for="input-1"
        class="grap-4"
      >
        <b-form-input
          id="lePhiInput"
          type="text"
          placeholder="Nhập lệ phí"
          required
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Số bộ hồ sơ"
        label-for="input-1"
        class="grap-4"
      >
        <b-form-input
          id="input-1"
          v-model.number="dataForm.soBoHoSo"
          v-block-e
          v-format-positive-integers
          v-is-int
          type="number"
          placeholder="Nhập số bộ hồ sơ"
          required
          @input="e => dataForm.soBoHoSo < 0 ? dataForm.soBoHoSo = 0 : dataForm.soBoHoSo"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Kiểu thủ tục hành chính"
        label-for="input-1"
        placeholder="Chọn kiểu thủ tục hành chính"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="kieuThuTucHanhChinh"
          :rules="{ required: true }"
          name="kieuThuTucHanhChinh"
        >
          <v-select
            ref="kieuThuTucHanhChinh"
            v-model="dataForm.kieuTTHC"
            class="style-chooser"
            placeholder="Chọn thủ tục hành chính"
            :options="ktthc"
            name="label"
            :reduce="se => se.id"
            :filter="onFilterSelect"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Mức độ dịch vụ công"
        label-for="input-1"
        placeholder="Chọn mức độ dịch vụ công"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="mucDoDichVuCong"
          :rules="{ required: true }"
          name="mucDoDichVuCong"
        >
          <v-select
            ref="mucDoDichVuCong"
            v-model="dataForm.loaiDichVuCong"
            class="style-chooser"
            placeholder="Chọn mức độ dịch vụ công"
            :options="mucDoDichVuCong"
            name="label"
            :reduce="se => se.id"
            :filter="onFilterSelect"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Loại dịch vụ công"
        label-for="input-1"
        placeholder="Chọn loại dịch vụ công"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="loaiDichVuCong"
          :rules="{ required: true }"
          name="loaiDichVuCong"
        >
          <v-select
            ref="loaiDichVuCong"
            v-model="dataForm.loaiDichVuCong2"
            class="style-chooser"
            placeholder="Chọn loại dịch vụ công"
            :options="loaiDichVuCong"
            name="label"
            :reduce="se => se.id"
            :filter="onFilterSelect"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Cấp độ thủ tục hành chính"
        label-for="input-1"
        placeholder="Chọn cấp độ thủ tục hành chính"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="capDoThuTucHanhChinh"
          :rules="{ required: true }"
          name="capDoThuTucHanhChinh"
        >
          <v-select
            ref="capDoThuTucHanhChinh"
            v-model="dataForm.capDoTTHC"
            class="style-chooser"
            placeholder="Chọn cấp độ thủ tục hành chính"
            :options="capDoTTHC"
            name="label"
            :reduce="se => se.id"
            :filter="onFilterSelect"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
        class="grap-6"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          class="custom-switch"
          switch
        />
      </b-form-group>
    </div>
    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Cấp độ chi tiết của thủ tục hành chính"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="capDoChiTietThuTucHanhChinh"
        :rules="{ required: true }"
        name="Cấp độ chi tiết thủ tục hành chính"
      >
        <b-form-radio-group
          id="radio-group-1"
          v-model="dataForm.capDoChiTietTTHC"
          :options="optionsCDCTTTHC"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      v-show="dataForm.capDoChiTietTTHC == 4"
      id="input-group-1"
      label="Đường dẫn chuyên ngành"
      label-for="input-1"
      class=""
    >
      <b-form-input
        v-show="dataForm.capDoChiTietTTHC == 4"
        id="input-1"
        ref="duongDanChuyenNganh"
        v-model="dataForm.duongDanChuyenNganh"
        type="text"
        placeholder="Nhập đường dẫn chuyên ngành"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label=""
      label-for="input-1"
    >
      <div class="grid-custom">
        <span class="d-flex">
          Triển khai tiếp nhận qua bưu điện
          <b-form-checkbox
            v-model="dataForm.tiepNhanQuaBuuDien"
            class="ml-1"
          />
        </span>
        <span class="d-flex">
          Triển khai trả kết quả qua bưu điện
          <b-form-checkbox
            v-model="dataForm.traKetQuaQuaBuuDien"
            class="ml-1"
          />
        </span>
      </div>
    </b-form-group>
    <b-form-group
      label="Mẫu hồ sơ"
      class="required"
    >

      <vue-good-table
        :columns="columns"
        :rows="dataForm.dsMauHoSo"
      >
        <div
          slot="emptystate"
          class="text-center"
        >
          Không có dữ liệu
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'tenMauHoSo'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`hoSo_${props.index}`"
            >
              <b-form-input
                id="input-1"
                :ref="`hoSo_${props.index}`"
                v-model="dataForm.dsMauHoSo[props.index].tenMauHoSo"
                type="text"
                placeholder="Nhập tên hồ sơ"
                :disabled="dataForm.dsMauHoSo[props.index].isDefault"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <div class="d-flex justify-content-center">
              <plus-square-icon
                v-if="props.index + 1 == dataForm.dsMauHoSo.length"
                v-b-tooltip.hover.v-secondary
                title="Thêm"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="e => dataForm.dsMauHoSo.push({ tenMauHoSo: null, isDefault: false, allowDelete: true, })"
              />
              <trash-2-icon
                v-if="dataForm.dsMauHoSo.length > 1 && !dataForm.dsMauHoSo[props.index].isDefault && dataForm.dsMauHoSo[props.index].allowDelete"
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteHoSo(props.index)"
              />
            </div>
          </span>
          <span v-else-if="props.column.field == 'STT'">
            {{ rowNumber(props) }}
          </span>
        </template>
      </vue-good-table>
    </b-form-group>
    <div>
      <b-form-group
        id="input-group-1"
        label="Mẫu đơn, tờ khai"
        label-for="input-1"
      >
        <div class="custom-grid-xl-container">
          <div class="grap-2">
            <b-button
              variant="primary"
              class="d-flex"
              size="sm"
              :disabled="!isLoadedFile"
              @click="(e) => $refs.fileInput.click()"
            >
              <b-spinner
                v-if="!isLoadedFile"
                small
                label="Spinning"
                style="margin-right: 5px;"
              />
              Tải file
            </b-button>
            <input
              ref="fileInput"
              type="file"
              class="d-none"
              multiple
              accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
              @change="changeFile"
            >
          </div>
          <div
            v-if="dataForm.mauDonToKhai && dataForm.mauDonToKhai.length > 0"
            class="grap-10"
          >
            <div
              v-for="(mdtk, index) in dataForm.mauDonToKhai"
              :key="index"
            >
              <span class="mr-1">
                <a
                  href="javascript:;"
                  @click="downloadFile(mdtk)"
                >{{ mdtk.tenFile }}</a>
              </span>
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteFile(index)"
              />
            </div>
          </div>
          <div
            v-else
            class="grap-10 d-flex align-items-center"
          >
            Cho phép tải lên file .doc, .pdf, .xls, .docx, .xlsx, .zip, .rar, .jpg, .png, .bmp, .gif – Nhỏ hơn 25MB
          </div>
        </div>
        <span class="label-noti-validate">{{ errorFileSize }}</span>
      </b-form-group>
    </div>
    <details class="format-details">
      <summary>Trình tự thực hiện</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.trinhTuThucHien"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Thành phần hồ sơ</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.thanhPhanHoSo"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Yêu cầu điều kiện</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.yeuCauDieuKien"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Thời gian giải quyết</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.thoiGianGiaiQuyet"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Đối tượng thực hiện</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.doiTuongThucHien"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Kết quả giải quyết</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.ketQuaGiaiQuyet"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Căn cứ pháp lý</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.canCuPhapLy"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Cách thức thực hiện</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.cachThucThucHien"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Cơ quan có thẩm quyền</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.coQuanCoThamQuyen"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Địa chỉ tiếp nhận hồ sơ</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.diaChiTiepNhanHoSo"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Cơ quan được ủy quyền</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.coQuanDuocUyQuyen"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
    <details class="format-details">
      <summary>Cơ quan phối hợp</summary>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.coQuanPhoiHop"
          :editor="editor"
          :config="editorConfig"
        />
      </b-form-group>
    </details>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
  VBTooltip,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import {
  LOAI_DICH_VU_CONG,
  CAP_DO_TTHC,
  OPTIONS_CDCTTTHC,
  KIEU_THU_TUC_HANH_CHINH, MUC_DO_VU_CONG,
} from '@/modules/danh-muc/constants/constants'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { PlusSquareIcon, Trash2Icon } from 'vue-feather-icons'
import END_POINTS from '@/api/endpoints'
import { removeDiacritical } from '@/utils/index'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import $ from 'jquery'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
// import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import '@/utils/vi'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    VueGoodTable,
    Trash2Icon,
    PlusSquareIcon,
    BSpinner,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ktthc: KIEU_THU_TUC_HANH_CHINH,
      mucDoDichVuCong: MUC_DO_VU_CONG,
      loaiDichVuCong: LOAI_DICH_VU_CONG,
      capDoTTHC: CAP_DO_TTHC,
      optionsCDCTTTHC: OPTIONS_CDCTTTHC,
      editor: ClassicEditor,
      editorConfig: {
        language: 'vi',
        placeholder: 'Nhập nội dung...',
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          PageBreak,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'pageBreak',
            'code',
            'undo',
            'redo',
          ],
        },
      },
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên mẫu hồ sơ',
          field: 'tenMauHoSo',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      lv: [],
      dvCbx: [],
      bmCbx: [],
      errorFileSize: null,
      isLoadedFile: true,
      data: {
        linhVucId: null,
        coQuanThucHien: null,
      },
      errorTrungMa: null,
    }
  },
  mounted() {
    try {
      this.getDataLinhVuc()
      this.getDataDonVi()
    } finally {
      this.$nextTick(() => {
        this.data.linhVucId = this.dataForm.linhVucId
        this.data.coQuanThucHien = this.dataForm.coQuanThucHien
        $('#phiInput').val(this.dataForm.phi)
        $('#lePhiInput').val(this.dataForm.lePhi)
      })
    }
    $(document).ready(() => {
      // eslint-disable-next-line func-names
      $('#phiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString('vi'))
        }
      })
      // eslint-disable-next-line func-names
      $('#lePhiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString('vi'))
        }
      })
    })
  },
  methods: {
    getDataPhi(name) {
      return $(`#${name}`).val() || 0
    },
    rowNumber(row) {
      return row.index + 1
    },
    getDataLinhVuc() {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX, this.payload).then(res => {
          if (res.data?.succeeded) {
            this.lv = res.data.data
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    getDataDonVi() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI, { kieuDonVi: 1 }).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dvCbx = this.listToTree(formatDonVi)
        }
      })
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
      }
    },
    deleteHoSo(idx) {
      this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.filter((item, index) => index !== idx)
    },
    changeFile(e) {
      this.isLoadedFile = true
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.dataForm.mauDonToKhai.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      try {
        file.forEach(item => {
          if (!this.dataForm.mauDonToKhai.find(ele => ele.tenFile.indexOf(item.name) >= 0) && item.size < 26214400) {
            if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
              fileResult.push(item)
            } else {
              filesNot.push(item.name)
            }
          } else if (item.size > 26214400) {
            fileNameSize.push(item.name)
          } else {
            fileName.push(item.name)
          }
        })
        fileResult = fileResult.filter((item, idx) => idx < numFile)
        filesAttck.push(...fileResult)
        if (fileName.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameError = ''
          fileName.forEach(item => {
            fileNameError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
        }
        if (fileNameSize.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameSizeError = ''
          fileNameSize.forEach(item => {
            fileNameSizeError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
        }
        if (filesNot.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let filesNotError = ''
          filesNot.forEach(item => {
            filesNotError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
        }
        if (filesAttck.length > 0) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          filesAttck.forEach(item => {
            formData.append('listFileData', item)
          })
          this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
            if (res.data) {
              const data = res.data.map(item => ({
                tenFile: item.fileName,
                path: item.objectName,
              }))
              this.dataForm.mauDonToKhai.push(...data)
            }
            this.isLoadedFile = true
          })
        }
      } finally {
        if (filesAttck.length < 0) {
          this.isLoadedFile = true
        }
      }
    },
    deleteFile(index) {
      this.dataForm.mauDonToKhai = this.dataForm.mauDonToKhai.filter((item, idx) => idx !== index)
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    onFilterSelectCoQuan(options, search) {
      return options.filter(item => removeDiacritical(item.tenDonVi).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
<style scped lang="scss">
.custom-switch{
  margin-top:10px !important;
}
</style>
