<template>
  <thu-tuc-hanh-chinh />
</template>
<script>
import ThuTucHanhChinh from '@/modules/danh-muc/components/pages/danh-muc/thu-tuc-hanh-chinh/ThuTucHanhChinh.vue'

export default {
  components: {
    ThuTucHanhChinh,
  },
}
</script>
