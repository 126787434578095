<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Mã biểu mẫu"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="maBieuMau"
        :rules="{ required: true }"
        name="maBieuMau"
      >
        <b-form-input
          id="input-1"
          ref="maBieuMau"
          v-model="form.maBieuMau"
          type="text"
          placeholder="Nhập mã biểu mẫu"
          required
          :autofocus="dataForm.id ? false : true"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="input-group-1"
      v-model="form.tenBieuMau"
      label="Tên biểu mẫu"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenBieuMau"
        :rules="{ required: true }"
        name="tenBieuMau"
      >
        <b-form-input
          id="input-1"
          ref="tenBieuMau"
          type="text"
          placeholder="Nhập tên biểu mẫu"
          required
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
    >
      <b-form-checkbox switch />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      form: {
        maBieuMau: '',
        tenBieuMau: '',
      },
    }
  },
}
</script>
