<template>
  <div>
    <div>
      <validation-observer
        ref="commonRules"
        class="custom-grid-xl-container"
      >
        <b-form-group
          id="input-group-1"
          label="Mẫu hồ sơ"
          label-for="input-1"
          class="required grap-6"
        >
          <validation-provider
            v-slot="{ errors }"
            key="mauHoSo"
            :rules="{ required: true }"
            name="mauHoSo"
          >
            <v-select
              ref="mauHoSo"
              v-model="form.mauHoSoId"
              :options="dataForm.mhs"
              :reduce="se => se.code"
              :autofocus="dataForm.id ? false : true"
              :disabled="form.id || isShow ? true : false"
              :filter="onFilterSelect"
              class="w-100 style-chooser"
              @input="changeMhs()"
            >
              <template #no-options>
                Không có dữ liệu
              </template>
            </v-select>
            <div v-if="isShow === true" />
            <div v-else>
              <span
                class="label-noti-validate"
              >{{ errors[0] }}</span></div>

          </validation-provider>
        </b-form-group>
        <div class="grap-6">
          <div class="d-flex justify-content-between grap-6">
            <label class="required">Giấy tờ</label>
            <a
              href="javascript:;"
              @click="addTenGiayTo()"
            >Thêm mới</a>
          </div>
          <validation-provider
            v-slot="{ errors }"
            key="giayTo"
            :rules="{ required: true }"
            name="giayTo"
          >
            <v-select
              ref="giayTo"
              v-model="form.giayToId"
              placeholder="Chọn giấy tờ"
              :options="gt"
              label="name"
              :reduce="se => se.id"
              class="w-100 style-chooser"
              :filter="onFilterSelect"
              :disabled="form.mauHoSoId && !isShow ? false : true"
            >
              <template #no-options>
                Không có dữ liệu
              </template>
            </v-select>
            <div v-if="isShow === true" />
            <div v-else>
              <span
                class="label-noti-validate"
              >{{ errors[0] }}</span></div>
          </validation-provider>
        </div>
        <b-form-group
          id="input-group-1"
          label="Bản chính"
          label-for="input-1"
          class="grap-2"
        >
          <b-form-input
            id="input-1"
            v-model.number="form.banChinh"
            v-block-e
            v-format-positive-integers
            v-is-int
            :disabled="isShow"
            type="number"
            placeholder="Nhập bản chính"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Bản sao"
          label-for="input-1"
          class="grap-2"
        >
          <b-form-input
            id="input-1"
            v-model.number="form.banSao"
            v-block-e
            v-format-positive-integers
            v-is-int
            :disabled="isShow"
            type="number"
            placeholder="Nhập bản sao"
            required
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Bản photo"
          label-for="input-1"
          class="grap-2"
        >
          <b-form-input
            id="input-1"
            v-model.number="form.banPhoto"
            v-block-e
            v-format-positive-integers
            v-is-int
            :disabled="isShow"
            type="number"
            placeholder="Nhập bản photo"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="isShow"
          id="input-group-1"
          label="Tên giấy tờ"
          label-for="input-1"
          class="required grap-6"
        >
          <validation-observer ref="subCommonRules">
            <validation-provider
              v-slot="{ errors }"
              key="tenGiayTo"
              :rules="{ required: true }"
              name="Giấy tờ"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  id="input-1"
                  v-model="tenGiayTo"
                  v-trim-input
                  type="text"
                  placeholder="Nhập tên giấy tờ"
                  required
                />
                <b-button
                  size="sm"
                  variant="primary"
                  class="ml-1"
                  style="width: 100px; padding: 9px 9px !important;"
                  @click="addGiayTo()"
                >
                  Thêm mới
                </b-button>
              </div>
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </b-form-group>
      </validation-observer>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        size="sm"
        variant="primary"
        class="mr-1"
        :disabled="isShow"
        @click="editorCommon()"
      >
        Lưu
      </b-button>
      <b-button
        size="sm"
        variant="danger"
        @click="addCommon()"
      >
        Đóng
      </b-button>
    </div>
    <vue-good-table
      ref="vgTable"
      class="mt-1"
      :columns="getColumn"
      :rows="rows"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        Không có dữ liệu
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'mauHoSoGiayTos'">
          <div
            v-for="item in props.row.mauHoSoGiayTos"
            :key="item.id"
          >- {{ item.tenGiayTo }}</div>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <edit-icon
            v-b-tooltip.hover.v-secondary
            title="Sửa"
            size="16"
            class="custom-class cursor-pointer mr-1"
            @click="editorPaper(props.row)"
          />
          <trash-2-icon
            v-b-tooltip.hover.v-secondary
            title="Xóa"
            size="16"
            class="custom-class cursor-pointer"
            @click="showDeleteModal(props.row)"
          />
        </span>
        <span v-else-if="props.column.field == 'STT'">
          {{ rowNumber(props) }}
        </span>
      </template>
    </vue-good-table>
    <common-modal
      ref="commonModal"
      :title="'Xác nhận'"
      :size="'sm'"
      @handle-ok="handleOk"
    />
  </div>
</template>
<script>
import {
  BFormGroup,
  VBTooltip,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import END_POINTS from '@/api/endpoints'
import {
  EditIcon,
  Trash2Icon,
} from 'vue-feather-icons'
import { removeDiacritical } from '@/utils/index'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BFormInput,
    EditIcon,
    Trash2Icon,
    BButton,
    VueGoodTable,
    CommonModal,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên mẫu hồ sơ',
          field: 'tenMauHoSo',
          width: '350px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          sortable: false,
        },
        {
          label: 'Bản chính',
          field: 'banChinh',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
          sortable: false,
        },
        {
          label: 'Bản sao',
          field: 'banSao',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
          sortable: false,
        },
        {
          label: 'Bản photo',
          field: 'banPhoto',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
          sortable: false,
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
          sortable: false,
        },
      ],
      rows: [],
      dv: [],
      gt: [],
      form: {
        mauHoSoId: null,
        giayToId: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
      },
      isCreate: false,
      errorFileSize: null,
      isLoadedFile: true,
      isShow: false,
      tenGiayTo: null,
      dataDelete: null,
    }
  },
  computed: {
    getColumn() {
      return this.isCreate ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    dataForm: {
      immediate: true,
      deep: true,
      handler(newData) {
        if (newData.rows && newData.rows.length > 0) {
          this.rows = newData.rows
        } else {
          this.rows = []
        }
        if (newData.mhs && newData.mhs.length === 1) {
          this.form.mauHoSoId = newData.mhs[0].code
          this.getDataGiayTo()
        }
      },
    },
  },
  mounted() {
    this.getDataDonVi()
  },
  methods: {
    rowNumber(row) {
      return row.index + 1
    },
    getDataGiayTo(idGaiyTo = null) {
      let url = END_POINTS.GIAY_TO.COMBOBOX
      if (this.form.mauHoSoId) {
        url += `?mauHoSoId=${this.form.mauHoSoId}`
      }
      this.$axios.get(url).then(res => {
        if (res.data?.succeeded) {
          this.gt = res.data.data
          if (idGaiyTo) {
            this.form.giayToId = idGaiyTo
          }
        }
      })
    },
    getDataDonVi() {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.CHUNG.DON_VI, this.payload).then(res => {
          if (res.data?.succeeded) {
            this.dv = res.data.data
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    async changeMhs() {
      if (!this.form.mauHoSoId) {
        this.form.giayToId = null
      }
      await this.getDataGiayTo()
      if (!this.gt.find(item => item.id === this.form.giayToId)) {
        this.form.giayToId = null
      }
    },
    deleteHoSo(idx) {
      this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.filter((item, index) => index !== idx)
    },
    editorPaper(data) {
      this.form = {
        id: data.id,
        mauHoSoId: data.mauHoSoId,
        giayToId: data.giayToId,
        banChinh: data.banChinh || 0,
        banSao: data.banSao || 0,
        banPhoto: data.banPhoto || 0,
      }
      this.$axios.get(END_POINTS.GIAY_TO.COMBOBOX).then(res => {
        const dsMauHoSo = this.rows.map(item => item.giayToId)
        if (res.data?.succeeded) {
          this.gt = res.data.data.filter(item => !dsMauHoSo.includes(item.id) || item.id === data.giayToId)
        }
      })
    },
    addCommon() {
      this.form = {
        mauHoSoId: null,
        giayToId: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
      }
      this.$refs.commonRules.reset()
      this.tenGiayTo = null
      this.isShow = false
      this.isCreate = false
    },
    addGiayTo() {
      this.$refs.subCommonRules.validate().then(success => {
        if (success) {
          this.$axios.post(END_POINTS.GIAY_TO.THEM, {
            maGiayTo: null,
            tenGiayTo: this.tenGiayTo,
            moTa: null,
            status: true,
            banChinh: 0,
            banPhoto: 0,
            banSao: 0,
          }).then(res => {
            if (res.data?.succeeded) {
              this.getDataGiayTo(res.data.data)
              this.tenGiayTo = null
              this.isShow = false
            }
          })
        }
      })
    },
    addTenGiayTo() {
      this.isShow = true
      this.tenGiayTo = null
      if (this.$refs.subCommonRules) {
        this.$refs.subCommonRules.reset()
      }
    },
    editorCommon() {
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.CAP_NHAT_GIAY_TO, this.form, true).then(res => {
            if (res.data?.succeeded) {
              this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: this.dataForm.tthcId }).then(result => {
                if (result.data?.succeeded) {
                  this.rows = result.data.data
                }
              })
              this.addCommon()
              this.$refs.commonRules.reset()
              this.isCreate = false
            }
          })
        }
      })
    },
    showDeleteModal(data) {
      this.dataDelete = data
      this.$refs.commonModal.showModal()
    },
    deleteCommon(data) {
      this.$axios.delete(`${END_POINTS.THU_TUC_HANH_CHINH.XOA_GT_MHS}?id=${data.id}`, null, true).then(res => {
        if (res.data?.succeeded) {
          this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: this.dataForm.tthcId }).then(result => {
            if (result.data?.succeeded) {
              this.rows = result.data.data
            }
          })
          this.isCreate = false
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name || item.label).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleOk() {
      this.deleteCommon(this.dataDelete)
    },
  },
}
</script>
