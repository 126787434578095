<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Mã giấy tờ"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="maGiayTo"
        :rules="{ required: true }"
        name="maGiayTo"
      >
        <b-form-input
          id="input-1"
          ref="maGiayTo"
          v-model="dataForm.maGiayTo"
          v-trim-input
          placeholder="Nhập mã giấy tờ"
          required
          :autofocus="dataForm.id ? false : true"
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
        <div class="label-noti-validate">
          {{ errorTrungMa }}
        </div>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Tên giấy tờ"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenGiayTo"
        :rules="{ required: true }"
        name="tenGiayTo"
      >
        <b-form-input
          id="input-1"
          ref="tenGiayTo"
          v-model="dataForm.tenGiayTo"
          v-trim-input
          type="text"
          placeholder="Nhập tên giấy tờ"
          required
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
    >
      <b-form-checkbox
        v-model="dataForm.status"
        switch
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Mô tả"
      label-for="input-1"
    >
      <b-form-textarea
        id="textarea"
        v-model="dataForm.moTa"
        v-trim-input
        placeholder="Nhập mô tả"
        rows="3"
        max-rows="6"
      />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
}
</script>
