<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Mã kiểu hồ sơ"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="maKieuHoSo"
        :rules="{ required: true }"
        name="Mã kiểu hồ sơ"
      >
        <b-form-input
          id="input-1"
          type="text"
          placeholder="Nhập mã kiểu hồ sơ"
          :autofocus="dataForm.id ? false : true"
          required
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
        <div class="label-noti-validate">
          {{ errorTrungMa }}
        </div>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Tên kiểu hồ sơ"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenKieuHoSo"
        :rules="{ required: true }"
        name="Tên kiểu hồ sơ"
      >
        <b-form-input
          id="input-1"
          type="text"
          placeholder="Nhập tên kiểu hồ sơ"
          required
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
    >
      <b-form-checkbox switch />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
}
</script>
