<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'ayncBtn', 'reFresh']"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @onEditorProfile="editorProfile"
      @refresh-page="refreshCommon()"
      @onEditorForm="editorForm"
      @onEditorPaper="editorPaper"
      @async-data="asyncData"
    />
    <good-table
      ref="vgTable"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'tenLinhVuc'">
          <treeselect
            id="linhVuc"
            ref="tenThuTucHanhChinh"
            v-model="payload.listLinhVucId"
            v-format-v-select
            :default-options="lv"
            placeholder="Chọn lĩnh vực"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            class="vtreeselect-chooser"
            :load-options="onChange"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName}"
              :title="node.label"
              :class="labelClassName"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
        <span v-else-if="props.column.field == 'tenCoQuan'">
          <treeselect
            id="linhVuc"
            ref="tenThuTucHanhChinh"
            v-model="payload.listCoQuanId"
            v-format-v-select
            :default-options="dv"
            placeholder="Chọn cơ quan"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizerDonVi"
            :match-keys="['label', 'label2']"
            class="vtreeselect-chooser"
            :load-options="onChangeQuery"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName}"
              :title="node.label"
              :class="labelClassName"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
        <span v-else-if="props.column.field == 'status'">
          <treeselect
            ref="status"
            v-model="payload.status"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="optionsTrangThai"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn trạng thái"
            :clear-on-select="true"
            @input="getDataThuTucHanhChinh"
          />
        </span>
      </template>
      <div
        slot="actions"
        slot-scope="{ props }"
      >
        <edit-icon
          v-b-tooltip.hover.v-secondary
          title="Sửa"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="updateCommom(props.row)"
        />
        <edit-3-icon
          v-b-tooltip.hover.v-secondary
          title="Sửa giấy tờ"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="editorPaper(props.row)"
        />
        <trash-2-icon
          v-b-tooltip.hover.v-secondary
          title="Xóa"
          size="16"
          class="custom-class cursor-pointer"
          @click="deleteCommomTable(props.row)"
        />
      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
        @update-data="editorPaper"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          v-if="componentName === 'ThuTucHanhChinhFrom'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="handleOkNotEmty"
        >
          Lưu
        </b-button>
        <b-button
          v-if="componentName === 'ThuTucHanhChinhFrom'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="handleOkEmty"
        >
          Ghi tiếp
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonnModal.hideModal(),$refs.commonModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import AppTable from '@/components/AppTable.vue'
import ThuTucHanhChinhFrom from '@/modules/danh-muc/components/form/ThuTucHanhChinhForm.vue'
import GiayToMauHoSoFrom from '@/modules/danh-muc/components/form/GiayToMauHoSoForm.vue'
import KieuHoSoFrom from '@/modules/danh-muc/components/form/KieuHoSoFrom.vue'
import BieuMauFrom from '@/modules/danh-muc/components/form/BieuMauFrom.vue'
import _debounce from 'lodash/debounce'
import GiayToFrom from '@/modules/danh-muc/components/form/GiayToForm.vue'
import {
  EditIcon,
  Trash2Icon,
  Edit3Icon,
} from 'vue-feather-icons'
import { removeDiacritical, checkStatus } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    AppTable,
    ThuTucHanhChinhFrom,
    BieuMauFrom,
    KieuHoSoFrom,
    GiayToFrom,
    GiayToMauHoSoFrom,
    GoodTable,
    EditIcon,
    Trash2Icon,
    Edit3Icon,
    BButton,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      isShowFooter: false,
      title: 'Thêm mới thủ tục hành chính',
      optionsTrangThai: STATUS,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã thủ tục',
          field: 'maTTHC',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục',
          field: 'tenTTHC',
          width: '300px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Cơ quan',
          field: 'tenCoQuan',
          thClass: 'text-center',
          width: '250px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Lĩnh vực',
          field: 'tenLinhVuc',
          thClass: 'text-center',
          width: '300px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          thClass: 'text-center',
          tdClass: 'text-left',
          width: '180px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      selectedItems: [],
      rows: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        maTTHC: null,
        tenTTHC: null,
        listCoQuanId: [],
        listCoQuanText: null,
        listTrangThaiId: [],
        listTrangThaiText: null,
        listBieuMauId: [],
        listBieuMauText: null,
        listLinhVucId: [],
        listLinhVucText: null,
        listKieuHoSoId: [],
        listKieuHoSoText: null,
        pageNumber: 1,
        pageSize: 10,
      },
      listCoQuanId: null,
      listLinhVucId: null,
      dataForm: {
        maTTHC: null,
        tenTTHC: null,
        linhVucId: null,
        coQuanThucHien: null,
        phi: 0,
        lePhi: 0,
        kieuTTHC: 2,
        loaiDichVuCong: 3,
        capDoTTHC: 1,
        capDoChiTietTTHC: 1,
        tiepNhanQuaBuuDien: false,
        traKetQuaQuaBuuDien: false,
        trinhTuThucHien: null,
        thanhPhanHoSo: null,
        yeuCauDieuKien: null,
        thoiGianGiaiQuyet: null,
        doiTuongThucHien: null,
        ketQuaGiaiQuyet: null,
        canCuPhapLy: null,
        bieuMauId: null,
        kieuHoSoId: null,
        status: true,
        dsMauHoSo: [
          {
            tenMauHoSo: null,
            isDefault: true,
          },
        ],
      },
      isLoading: false,
      size: null,
      isShow: false,
      isEmty: false,
      lv: [],
      dv: [],
    }
  },
  created() {
    this.getDataThuTucHanhChinh()
    this.getDataLinhVuc()
    this.getDataDonVi()
  },
  methods: {
    openModal() {
      this.isShowFooter = true
      this.$refs.commonnModal.showModal()
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    onChangeQuery({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.dv.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQuery(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQuery: _debounce(async function (query, callback) {
      const response = this.lv.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    normalizerDonVi(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
      }
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    getDataDonVi() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI, { kieuDonVi: 1 }).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dv = this.listToTree(formatDonVi)
        }
      })
    },
    getDataLinhVuc() {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX, this.payload).then(res => {
          if (res.data?.succeeded) {
            this.lv = res.data.data
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    getDataThuTucHanhChinh() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    addCommom() {
      this.$router.push({ path: '/thu-tuc-hanh-chinh/tm-tthc' })
      // this.title = 'Thêm mới thủ tục hành chính'
      // this.componentName = 'ThuTucHanhChinhFrom'
      // this.size = 'xl'
      // this.dataForm = {
      //   maTTHC: null,
      //   tenTTHC: null,
      //   linhVucId: null,
      //   coQuanThucHien: null,
      //   phi: 0,
      //   lePhi: 0,
      //   kieuTTHC: 2,
      //   loaiDichVuCong: 3,
      //   capDoTTHC: 1,
      //   capDoChiTietTTHC: 1,
      //   tiepNhanQuaBuuDien: false,
      //   traKetQuaQuaBuuDien: false,
      //   trinhTuThucHien: null,
      //   thanhPhanHoSo: null,
      //   yeuCauDieuKien: null,
      //   thoiGianGiaiQuyet: null,
      //   doiTuongThucHien: null,
      //   ketQuaGiaiQuyet: null,
      //   canCuPhapLy: null,
      //   bieuMauId: null,
      //   kieuHoSoId: null,
      //   status: true,
      //   mauDonToKhai: [],
      //   soBoHoSo: 1,
      //   dsMauHoSo: [
      //     {
      //       tenMauHoSo: 'Bình thường',
      //       isDefault: true,
      //     },
      //   ],
      // }
      // this.isShow = true
      // this.$refs.commonModal.showModal()
    },
    editorProfile() {
      this.title = 'Cập nhật kiểu hồ sơ'
      this.componentName = 'KieuHoSoFrom'
      this.isShow = false
      this.$refs.commonModal.showModal()
    },
    editorForm() {
      this.title = 'Cập nhật biểu mẫu'
      this.componentName = 'BieuMauFrom'
      this.isShow = false
      this.$refs.commonModal.showModal()
    },
    editorPaper(data) {
      this.title = 'Cập nhật giấy tờ'
      this.componentName = 'GiayToMauHoSoFrom'
      this.size = 'xl'
      Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: data.id }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: data.id })]).then(res => {
        this.dataForm.rows = res[0].data.data
        this.dataForm.mhs = res[1].data.data ? res[1].data.data.map(item => ({
          code: item.id,
          label: item.tenMauHoSo,
        })) : []
        this.dataForm.tthcId = data.id
        this.$refs.commonModal.showModal()
        this.isShow = true
      })
    },
    onSelectRow(data) {
      this.$isDisabelBtn = data.length
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    handleOkNotEmty() {
      this.isEmty = false
      this.$refs.commonModal.checkValidate()
    },
    handleOkEmty() {
      this.isEmty = true
      this.$refs.commonModal.checkValidate()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.THU_TUC_HANH_CHINH.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataThuTucHanhChinh()
            this.$refs.commonModal.hideModal()
            if (res.data?.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          }
        })
      } else if (this.componentName === 'ThuTucHanhChinhFrom') {
        this.dataForm.mauDonToKhai = this.dataForm.mauDonToKhai || []
        this.dataForm.phi = this.$refs.componentName.getDataPhi('phiInput') === 0 ? 0 : this.getNumber(this.$refs.componentName.getDataPhi('phiInput'))
        this.dataForm.lePhi = this.$refs.componentName.getDataPhi('lePhiInput') === 0 ? 0 : this.getNumber(this.$refs.componentName.getDataPhi('lePhiInput'))
        if (this.dataForm?.capDoChiTietTTHC !== 4) {
          this.dataForm.duongDanChuyenNganh = null
        }
        // eslint-disable-next-line prefer-const
        if (this.dataForm?.id) {
          this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.map(item => ({
            id: item.id,
            tenMauHoSo: item.tenMauHoSo,
          })) || []
          this.$axios.put(END_POINTS.THU_TUC_HANH_CHINH.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataThuTucHanhChinh()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
              this.$refs.componentName.$refs.maThuTucHanhChinh.focus()
            }
          })
        } else {
          this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.map(item => ({
            tenMauHoSo: item.tenMauHoSo,
          })) || []
          this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.$refs.commonModal.hideModal()
              if (this.isEmty) {
                this.addCommom()
              }
              this.getDataThuTucHanhChinh()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
              this.$refs.componentName.$refs.maThuTucHanhChinh.focus()
            }
          })
        }
      }
    },
    getNumber(str) {
      const arr = str.split('')
      // eslint-disable-next-line prefer-const
      let out = []
      arr.forEach(item => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(item) === false) {
          out.push(item)
        }
      })
      return Number(out.join(''))
    },
    updateCommom(data) {
      this.$router.push({ path: '/thu-tuc-hanh-chinh/sua-tthc', query: { id: data.id } })
      // this.title = 'Cập nhật thủ tục hành chính'
      // this.componentName = 'ThuTucHanhChinhFrom'
      // this.size = 'xl'
      // this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET, { id: data.id }).then(res => {
      //   if (res.data?.succeeded) {
      //     const result = res.data.data
      //     this.dataForm = {
      //       ...result,
      //       dsMauHoSo: result.mauHoSos.map(item => ({
      //         id: item.id,
      //         tenMauHoSo: item.tenMauHoSo,
      //         isDefault: item.tenMauHoSo === 'Bình thường',
      //         allowDelete: item.allowDelete,
      //       })) || [],
      //       phi: result.phi ? result.phi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      //       lePhi: result.lePhi ? result.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      //       mauDonToKhai: result.mauDonToKhais && result.mauDonToKhais.length > 0 ? result.mauDonToKhais : [],
      //     }
      //     this.isShow = false
      //     this.$refs.commonModal.showModal()
      //   }
      // })
    },
    deleteCommom() {
      this.title = 'Xác nhận'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.isShow = false
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.title = 'Xác nhận'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.selectedItems = [data]
      this.isShow = false
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        maTTHC: data?.maTTHC ? data?.maTTHC.replace(/\s+/g, ' ').trim() : null,
        tenTTHC: data?.tenTTHC ? data?.tenTTHC.replace(/\s+/g, ' ').trim() : null,
        listCoQuanId: this.payload.listCoQuanId,
        listCoQuanText: null,
        listTrangThaiId: [],
        listTrangThaiText: null,
        listBieuMauId: [],
        listBieuMauText: null,
        listLinhVucId: this.payload.listLinhVucId,
        listLinhVucText: null,
        listKieuHoSoId: [],
        listKieuHoSoText: null,
        listNhomPhieuuId: [],
        status: this.payload.status,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    onFilterSelectCoQuan(options, search) {
      return options.filter(item => removeDiacritical(item.tenDonVi).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc', 'coQuanBanHanh'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
    asyncData() {
      this.$toast.success('Đồng bộ thành công')
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.DONG_BO, {}, false).then(res => {
        if (res.data.code === 200) {
          this.getDataThuTucHanhChinh()
          this.$toast.success(res.data.message)
        } else {
          this.$toast.error('Đồng bộ thất bại')
        }
      })
    },
    resetFilter() {
      this.payload = {
        maTTHC: null,
        tenTTHC: null,
        listCoQuanId: [],
        listCoQuanText: null,
        listTrangThaiId: [],
        listTrangThaiText: null,
        listBieuMauId: [],
        listBieuMauText: null,
        listLinhVucId: [],
        listLinhVucText: null,
        listKieuHoSoId: [],
        listKieuHoSoText: null,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataThuTucHanhChinh()
      })
    },
    refreshCommon() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.getDataThuTucHanhChinh()
    },
  },
}
</script>
